import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import debounce from 'debounce-promise'

import { GET_LIST, CREATE, DELETE, useClient } from '@peracto/client'
import { Modal, LoadingIcon } from '@peracto/peracto-ui'
import ProductAssignmentList from './ProductAssignmentList'

const ProductAssignment = ({ category }) => {
    const { client } = useClient()
    const [listLoading, setListLoading] = useState(false)
    const [itemToAdd, setItemToAdd] = useState()
    const [showAddProductDialog, setShowAddProductDialog] = useState(false)

    const getProductName = (products) => {
        if (products?.attributes) {
            const product = products.attributes.map((attr) => {
                if (attr?.attribute?.code === 'product_name') {
                    return `${attr.value} (${products.sku})`
                } else {
                    return null
                }
            })

            return product.find((item) => typeof item === 'string')
        } else {
            return '-'
        }
    }

    const onAddProduct = async (product) => {
        setListLoading(true)
        try {
            await client(CREATE, 'category-product-links', {
                data: {
                    category: category.id,
                    product: product.id,
                },
            })
            setListLoading(false)

            toast.success('Category Product Link added successfully!')
        } catch (e) {
            console.error(e)
            const violations = e.error.body.violations
            if (violations.length > 0) {
                const errMsg = violations.find((err) => err.propertyPath === 'category')
                toast.error(`Error: ${errMsg.message}`)
            } else {
                toast.error('Whoops, there was a problem...')
            }
            setListLoading(false)
        }
    }

    const onDelete = async (id) => {
        setListLoading(true)
        try {
            await client(DELETE, 'category-product-links', {
                id,
            })
            setListLoading(false)

            toast.success('Category Product Link deleted successfully!')
        } catch (e) {
            console.error(e)
            const violations = e.error.body.violations
            if (violations.length > 0) {
                const errMsg = violations.find((err) => err.propertyPath === 'category')
                toast.error(errMsg.message)
            } else {
                toast.error('Whoops, there was a problem...')
            }
            setListLoading(false)
        }
    }

    const fetchProducts = async (inputValue) => {
        const { data } = await client(GET_LIST, 'products', {
            id: 'products',
            name: inputValue,
        })

        return data.map((product) => ({
            label: `${getProductName(product)}`,
            value: product,
        }))
    }

    const debouncedFetchProducts = debounce(fetchProducts, 200)

    if (listLoading) {
        return (
            <div className="d-flex flex-grow-1 align-items-center flex-column justify-content-center py-5 h-100">
                <LoadingIcon />
            </div>
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <AsyncSelect
                        className="w-100 mb-3"
                        loadOptions={(input) => debouncedFetchProducts(input)}
                        isSearchable={true}
                        onChange={(option) => {
                            setItemToAdd(option)
                            setShowAddProductDialog(true)
                        }}
                        value={null}
                        defaultOptions={true}
                        placeholder="Search for Products to add..."
                        noOptionsMessage={({ inputValue }) => {
                            if (inputValue.length > 0) {
                                return `No results found for '${inputValue}'.`
                            } else {
                                return 'Enter text to begin searching.'
                            }
                        }}
                    />
                </div>
            </div>

            <ProductAssignmentList category={category} onDelete={onDelete} />

            <Modal
                isVisible={showAddProductDialog}
                title="Add Product"
                close={() => setShowAddProductDialog(false)}
                buttons={[
                    {
                        type: 'btn-outline-secondary',
                        text: 'Close',
                        action: () => {
                            setShowAddProductDialog(false)
                        },
                    },
                    {
                        type: 'btn-success',
                        text: 'Add Product',
                        action: () => {
                            setShowAddProductDialog(false)
                            onAddProduct(itemToAdd.value)
                        },
                    },
                ]}
            >
                <FontAwesomeIcon icon={faExclamationTriangle} size="4x" className="d-block mb-4" />
                Are you sure you would like to add{' '}
                {itemToAdd?.label ? itemToAdd.label : 'this product'} to this category?
            </Modal>
        </>
    )
}

export default ProductAssignment
