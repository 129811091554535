import styled from 'styled-components'

export const ColourSwatch = styled.span.attrs(props => ({
    bgcolour: `${props.bgcolour}`,
}))`
    &:after {
        content: '';
        display: inline-block;
        position: relative;
        top: 2px;
        height: 14px;
        width: 14px;
        margin-left: 3px;

        border-radius: 3px;
        background-color: ${props => props.bgcolour};
    }
`
