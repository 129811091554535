import React from 'react'
import PropTypes from 'prop-types'
import Resources from './Lists/Resources'

const ResourcesListRenderer = ({ groups }) => {
    return (
        <>
            {groups.map((group) => (
                <Resources
                    key={`resources_${group.value}`}
                    groupLabel={group.label}
                    group={group.value}
                />
            ))}
        </>
    )
}

ResourcesListRenderer.propTypes = {
    groups: PropTypes.array.isRequired,
}

export default ResourcesListRenderer
