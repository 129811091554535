import React from 'react'
import { operatorValues } from './ConditionsListForm'
import * as S from './styled'

const ConditionsListDetails = ({ values, toggleRow }) => {
    const ConditionValue = ({ value }) => {
        /* Show colour if value is a hex colour value */
        if (value.match(/^#([0-9a-f]{6}|[0-9a-f]{3})$/i)) {
            return <S.ColourSwatch bgcolour={value}>{value}</S.ColourSwatch>
        }

        return value
    }
    const getOperatorLabel = (operator) => {
        const option = operatorValues.find((val) => val.value === operator)
        return option.label
    }

    const getListOfValues = (values, operator) => {
        return values.values.map((val, idx) => (
            <span key={`${values.attribute.code}_${idx}`}>
                {`${getOperatorLabel(val.operation)}`} <ConditionValue value={val.value} />{' '}
                {idx !== values.values.length - 1 && <em>{operator} </em>}
            </span>
        ))
    }

    if (!values.attribute.label) {
        return null
    }

    return (
        <div className="border-bottom py-3">
            <div className="row">
                <div className="col-12 col-md-3">{`${values.attribute.label} (${values.attribute.code})`}</div>
                <div className="col-12 col-md-6">{getListOfValues(values, values.operator)}</div>
                <div className="col-12 col-md-3">
                    <div className="text-right">
                        <button className="btn btn-link p-0" onClick={() => toggleRow()}>
                            Edit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConditionsListDetails
