import React from 'react'
import { ThemeProvider } from 'styled-components'

import Peracto from '@peracto/peracto'
import Config from '@peracto/peracto-config'
import PeractoUser from '@peracto/peracto-user'
import PeractoCatalogue from '@peracto/peracto-catalogue'
import PeractoCommon from '@peracto/peracto-common'
import PeractoContent from '@peracto/peracto-content'
import PeractoLocations from '@peracto/peracto-locations'
import PeractoPromotions from '@peracto/peracto-promotions'

import { CategoryForm } from './packages/Catalogue'
import { UserAdd, UserEdit, UserForm } from './packages/User'

import { EditorTheme, AdminTheme } from './theme'
import blocks from './blocks'

const config = new Config({
    api: process.env.REACT_APP_API,
    modules: [
        PeractoUser({ UserAdd, UserEdit, UserForm }),
        PeractoCommon(),
        PeractoCatalogue({ CategoryForm }),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
    ],
    editorConfig: {
        theme: EditorTheme,
        colorOptions: [
            { label: 'Brand Black', value: '#000000' },
            { label: 'Brand Yellow', value: '#FDC938' },

            { label: 'Secondary Success', value: '#2DB200' },
            { label: 'Secondary Danger', value: '#DD0808' },
            { label: 'Secondary Highlight', value: '#FFF2CD' },

            { label: 'Dark Grey', value: '#505050' },
            { label: 'Grey', value: '#D8D8D8' },
            { label: 'Light Grey', value: '#F2F2F2' },
        ],
        locales: ['en_GB', 'es_ES', 'fr_FR'],
        blocks,
        inputTypes: {},
    },
    features: {
        shippingServices: {
            deliveryDays: true,
        },
    },
})

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
)

export default App
