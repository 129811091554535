import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import Sortable from 'react-sortablejs'
import AsyncSelect from 'react-select/async'
import debounce from 'debounce-promise'
import { Field, FieldArray, getIn } from 'formik'

import {
    Form,
    Group,
    Input,
    // File,
    Select,
    Textarea,
    Help,
    FormGroup,
    RichText,
    FormActions,
} from '@peracto/peracto-ui'

import { GET_LIST, useClient } from '@peracto/client'

import ConditionsList from './ConditionsList'
import ProductAssignment from './ProductAssignment'
import SearchEnginePreview from './SearchEnginePreview'
import ResourcesListRenderer from './ResourcesListRenderer'

import { SortableRow } from '@peracto/peracto-ui'

export const MODE_ADD = 'add'
export const MODE_EDIT = 'edit'

const CategoryForm = ({ mode = MODE_EDIT, values = {}, ...props }) => {
    const { client } = useClient()
    const [filters, setFilters] = useState([])
    const [templateType, setTemplateType] = useState('')
    const [productAssignment, setProductAssignment] = useState(false)
    const [showSEOForm, setShowSEOForm] = useState(false)

    // TODO: Pull these from config when they're available
    const CATEGORY_RESOURCES = [
        {
            label: 'Main Image',
            value: 'main_image',
        },
        {
            label: 'Secondary Image',
            value: 'secondary_image',
        },
    ]

    const fetchFilters = async (inputValue, fieldVal) => {
        const { data } = await client(GET_LIST, 'attributes', {
            id: 'attributes',
            label: inputValue,
            isFilterable: true,
        })

        setFilters(data)

        const selectedValues = fieldVal ? fieldVal : []
        const values = data
            .filter((attr) => !selectedValues.includes(attr.code))
            .map((attr) => ({
                label: `${attr.label} (${attr.code})`,
                value: attr.id,
            }))

        return values
    }

    const debouncedFetchFilters = debounce(fetchFilters, 200)

    if (productAssignment) {
        return (
            <>
                <FormActions>
                    <button
                        type="button"
                        style={{ padding: '0', border: 'none', background: 'none' }}
                        onClick={() => setProductAssignment(false)}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                        Back to Category
                    </button>
                </FormActions>
                <Group key="manage-products" id="manage-products" name="Manage Products">
                    <ProductAssignment category={values} />
                </Group>
            </>
        )
    }

    return (
        <Form
            autoComplete="off"
            values={{
                ...values,
                status: values.status || 'active',
            }}
            {...props}
        >
            <Group key="core-data" id="core-data" name="Core Data">
                <Field name="heading">
                    {({ field, form }) => {
                        let error = getIn(form.errors, field.name)

                        if (error) {
                            error = error.replace(field.name, 'Category Heading')
                        }
                        if (!field.value) field.value = ''

                        return (
                            <FormGroup
                                name={field.name}
                                label="Heading"
                                help="Category heading will display as an H1 on the category page."
                                required
                                error={error}
                            >
                                <input
                                    type="text"
                                    className="form-control"
                                    {...field}
                                    disabled={form.isSubmitting}
                                    onBlur={() => {
                                        if (field.value.length > 0) {
                                            if (
                                                !form.values.metaTitle ||
                                                form.values.metaTitle.length === 0
                                            ) {
                                                form.setFieldValue('metaTitle', field.value)
                                            }

                                            if (
                                                !form.values.identifier ||
                                                form.values.identifier.length === 0
                                            ) {
                                                form.setFieldValue(
                                                    'identifier',
                                                    `${field.value
                                                        .split(' ')
                                                        .join('-')
                                                        .toLowerCase()}`,
                                                )
                                            }
                                        }
                                    }}
                                />
                            </FormGroup>
                        )
                    }}
                </Field>

                <Input
                    name="identifier"
                    label="Identifier"
                    help="This uniquely identifies the category."
                    required
                />

                <Select
                    name="status"
                    label="Status"
                    defaultValue={{ label: 'Active', value: 'active' }}
                    options={[
                        { label: 'Active', value: 'active' },
                        { label: 'Draft', value: 'draft' },
                        { label: 'Deleted', value: 'deleted' },
                    ]}
                    required
                />
            </Group>

            <Group key="content-and-layout" id="content-and-layout" name="Content and Layout">
                <Select
                    name="template"
                    label="Template"
                    options={[
                        {
                            label: 'Category Landing',
                            value: 'Category Landing',
                        },
                        {
                            label: 'Product List Machine Specific',
                            value: 'Product List Machine Specific',
                        },
                        {
                            label: 'Product List Non-machine Specific',
                            value: 'Product List Non-machine Specific',
                        },
                    ]}
                    help="Select the layout that should be used for this category"
                    handleChange={(value) => setTemplateType(value)}
                    required
                />

                <RichText name="description" label="Category Description" />

                <ResourcesListRenderer groups={CATEGORY_RESOURCES} />
            </Group>

            {templateType !== 'Category Landing' && (
                <>
                    <Group key="category-type" id="category-type" name="Category Type">
                        <Select
                            name="type"
                            label=""
                            help="For Standard categories products are assigned manually one by one. For Dynamic categories products that match the conditions you set will automatically be added"
                            required
                            options={[
                                {
                                    label: 'Standard',
                                    value: 'standard',
                                },
                                {
                                    label: 'Dynamic',
                                    value: 'dynamic',
                                },
                            ]}
                        />

                        <ConditionsList name="options.attributes" />

                        <Field name="type">
                            {({ field }) => (
                                <>
                                    {field.value === 'standard' && mode === MODE_EDIT && (
                                        <button
                                            className="btn btn-link px-0"
                                            onClick={() => setProductAssignment(true)}
                                            type="button"
                                        >
                                            <FontAwesomeIcon
                                                icon={['far', 'plus-circle']}
                                                className="mr-2"
                                            />
                                            Manage Products Assigned
                                        </button>
                                    )}
                                </>
                            )}
                        </Field>
                    </Group>
                </>
            )}

            <Group key="search-engine-data" id="search-engine-data" name="Search Engine Data">
                <div className="d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-link px-0"
                        onClick={() => setShowSEOForm(!showSEOForm)}
                    >
                        {showSEOForm ? 'Close' : 'Edit'}
                    </button>
                </div>
                {showSEOForm ? (
                    <>
                        <Input
                            name="identifier"
                            label="URL and Path"
                            prefix={`${window.location.origin}/c/`}
                        />

                        <Input name="metaTitle" label="Title" />

                        <Textarea name="metaDescription" label="Description" />
                    </>
                ) : (
                    <SearchEnginePreview />
                )}
            </Group>

            {templateType !== 'Category Landing' && (
                <Group key="filters" id="filters" name="Filters">
                    <div className="form-group">
                        <label className="mb-0">Active Filters</label>
                        <Help
                            id="filters-help"
                            help="
                            Select and order filters that you would like to use on this category page in addition to price.
                            Filters will only display if the result set contains at least one value for that attribute."
                        />
                    </div>
                    <Field name="filters">
                        {({ field, form }) => (
                            <>
                                <FieldArray name={field.name}>
                                    {() => (
                                        <table className="table table-sm">
                                            <colgroup>
                                                <col width="10%" />
                                                <col width="75%" />
                                                <col width="15%" />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Label</th>
                                                    <th className="text-right">Remove</th>
                                                </tr>
                                            </thead>

                                            <Sortable
                                                tag="tbody"
                                                onChange={(order, sortable, evt) => {
                                                    const values = [...field.value]
                                                    const movedItem = values[evt.oldIndex]
                                                    const to = evt.newIndex
                                                    values.splice(evt.oldIndex, 1)
                                                    values.splice(to, 0, movedItem)
                                                    form.setFieldValue('filters', values)
                                                }}
                                                options={{
                                                    animation: 100,
                                                }}
                                            >
                                                {field.value &&
                                                    field.value.length > 0 &&
                                                    field.value.map((item, index) => (
                                                        <SortableRow key={index} data-id={index}>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    icon={['far', 'grip-lines']}
                                                                />
                                                            </td>
                                                            <td>{item}</td>
                                                            <td className="text-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() => {
                                                                        const values = [
                                                                            ...field.value,
                                                                        ]
                                                                        values.splice(index, 1)
                                                                        form.setFieldValue(
                                                                            'filters',
                                                                            [...values],
                                                                        )
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={['far', 'trash']}
                                                                    />
                                                                </button>
                                                            </td>
                                                        </SortableRow>
                                                    ))}
                                            </Sortable>
                                        </table>
                                    )}
                                </FieldArray>
                                <div className="row">
                                    <div className="col-12">
                                        <AsyncSelect
                                            className="w-100"
                                            loadOptions={(input) =>
                                                debouncedFetchFilters(input, field.value)
                                            }
                                            isSearchable={true}
                                            onChange={(option) => {
                                                const selectedValue = filters.filter(
                                                    (attr) => attr.id === option.value,
                                                )
                                                const fieldValues = [...field.value]
                                                fieldValues.push(selectedValue[0].code)
                                                form.setFieldValue('filters', [...fieldValues])
                                            }}
                                            value={null}
                                            placeholder="Search for Filters to add..."
                                            noOptionsMessage={({ inputValue }) => {
                                                if (inputValue.length > 0) {
                                                    return `No results found for '${inputValue}'.`
                                                } else {
                                                    return 'Enter text to begin searching.'
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </Field>
                </Group>
            )}
        </Form>
    )
}

CategoryForm.displayName = 'CategoryForm'

CategoryForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default CategoryForm
