import { theme } from '@chakra-ui/core'
import styled from 'styled-components'

export const StyledButton = styled.button`
    padding: 0;
    border: none;
    background: none;
`

// [container, breakpoint]
const sizes = {
    sm: ['520px', '576px'],
    md: ['720px', '768px'],
    lg: ['960px', '992px'],
    xl: ['1170px', '1200px'],
}

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1])
    return bp
}, [])

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        green: {
            ...theme.colors.green,
            500: '#2DB200',
        },
        yellow: {
            ...theme.colors.green,
            500: '#FDC938',
        },
        brand: {
            black: '#000000',
            blackTransparent: 'hsla(0, 0%, 0%, 0.75)',
            blackTransparent50: 'hsla(0, 0%, 0%, 0.5)',
            white: '#FFFFFF',

            green: '#2DB200',
            red: '#DD0808',

            yellow: '#FDC938',
            lightYellow: '#FFF2CD',

            paleRed: '#FDF1F2',
            blue: '#24195D',
            electricBlue: '#0000A2',
            silver: '#F0F7FF',

            lighterGrey: '#F2F2F2',
            lightGrey: '#EDEDED',
            grey: '#D8D8D8',
            midGrey: '#808285',
            darkGrey: '#505050',
        },
    },
    fonts: {
        ...theme.fonts,
        heading: 'Roboto Condensed, sans-serif',
        body: 'Roboto, sans-serif',
        regular: 'Roboto, sans-serif',
        condensed: 'Roboto Condensed, sans-serif',
    },
}

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
}
