import React, { useState } from 'react'

import ConditionsListForm from './ConditionsListForm'
import ConditionsListDetails from './ConditionsListDetails'

const ConditionsListRow = ({ values, toggleRow, index, form, field, remove, ...props }) => {
    const [formVisible, setFormVisible] = useState(
        props.formVisible || !field.value[index].attribute.label
    )

    return (
        <>
            {formVisible ? (
                <ConditionsListForm
                    {...{
                        index,
                        form,
                        field,
                        remove,
                    }}
                    toggleRow={() => setFormVisible(false)}
                />
            ) : (
                <ConditionsListDetails
                    values={field.value[index]}
                    toggleRow={() => setFormVisible(true)}
                />
            )}
        </>
    )
}

export default ConditionsListRow
