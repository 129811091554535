import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'

import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CREATE, DELETE, GET_LIST, useClient } from '@peracto/client'
import { Form, Group, Input, Tags, Select, Modal, FormActions, Checkbox } from '@peracto/peracto-ui'
import { roleValues } from '@peracto/peracto-user'
import { StyledButton } from '../../../theme'
import * as S from './styled'
import AddressForm from './AddressForm'

export const MODE_ADD = 'add'
export const MODE_EDIT = 'edit'

const UserForm = ({
    mode = MODE_EDIT,
    values,
    setFormData = () => {},
    onSaveAddress,
    dataTestId,
    ...props
}) => {
    const [showUserDialog, setShowUserDialog] = useState(false)

    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [sendingPasswordReset, setSendingPasswordReset] = useState(false)
    const [visibleFields, setVisibleFields] = useState({})
    const [customerGroups, setCustomerGroups] = useState([])
    const [redirect, setRedirect] = useState()
    const { client } = useClient()

    const titles = [
        { label: 'Please Select...', value: '' },
        { label: 'Mr', value: 'Mr' },
        { label: 'Mrs', value: 'Mrs' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Ms', value: 'Ms' },
        { label: 'Mx', value: 'Mx' },
    ]

    const roles = [
        { label: 'Admin', value: 'ROLE_ADMIN' },
        { label: 'User', value: 'ROLE_USER' },
    ]

    const onDelete = async () => {
        try {
            await client(DELETE, 'users', {
                id: values.user.id,
            })

            toast.success('User deleted successfully!')
            setRedirect('/users')
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const onResetPassword = async () => {
        setSendingPasswordReset(true)
        try {
            await client(CREATE, 'users/reset-password', {
                data: {
                    email: values.user.email,
                },
            })

            setShowPasswordReset(false)
            setSendingPasswordReset(false)
            toast.success('Password reset email sent!')
        } catch (e) {
            console.error(e)
            setSendingPasswordReset(false)
            toast.error('Whoops, there was a problem...')
        }
    }

    const fetchCustomerGroups = useCallback(
        async (inputValue = '') => {
            const { data } = await client(GET_LIST, 'customer-groups', {
                id: 'customer-groups',
                label: inputValue,
            })

            const values = data.map((val) => ({
                label: val.name,
                value: val.id,
            }))

            setCustomerGroups(values)

            return values
        },
        [client],
    )

    useEffect(() => {
        fetchCustomerGroups()
    }, [fetchCustomerGroups])

    return (
        <>
            <div data-testid={dataTestId}>
                {redirect && <Redirect to={redirect} />}

                {mode === MODE_EDIT && (
                    <FormActions>
                        <>
                            <StyledButton
                                style={{ marginRight: '10px' }}
                                onClick={() => setShowPasswordReset(true)}
                            >
                                <FontAwesomeIcon icon={faLock} className="mr-2" />
                                Reset Password
                            </StyledButton>

                            <StyledButton
                                className="text-danger"
                                onClick={() => setShowUserDialog(true)}
                            >
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                Delete User
                            </StyledButton>
                        </>
                    </FormActions>
                )}

                <Form autoComplete="off" values={values} {...props}>
                    <Group key="customer" id="customer" name="Customer">
                        {mode === MODE_EDIT && (
                            <div className="d-flex justify-content-between align-items-start">
                                <div>
                                    {!visibleFields.customer && (
                                        <S.Address>
                                            <p className="address-name">
                                                {values.user.firstName} {values.user.lastName}
                                            </p>
                                            <p>{values.user.email}</p>
                                            <p>{values.user.telephone}</p>
                                            <p>
                                                {values.user.roles.map((val, idx) => (
                                                    <span key={`role-${idx}`}>
                                                        {idx > 0 ? ', ' : ''}
                                                        {roleValues[val]}
                                                    </span>
                                                ))}
                                            </p>
                                        </S.Address>
                                    )}
                                </div>

                                <button
                                    type="button"
                                    className="p-0 btn btn-link"
                                    onClick={() => {
                                        setVisibleFields({
                                            ...visibleFields,
                                            customer: !visibleFields.customer,
                                        })
                                    }}
                                >
                                    {visibleFields.customer ? (
                                        <>
                                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            Close
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                            Edit
                                        </>
                                    )}
                                </button>
                            </div>
                        )}

                        {(mode === MODE_ADD || visibleFields.customer) && (
                            <>
                                <Input
                                    data-testid="netsuite-customer-id"
                                    autoComplete="netsuite-customer-id"
                                    label="NetSuite Customer ID"
                                    name="user.netSuiteCustomerId"
                                />

                                <Input
                                    data-testid="netsuite-contact-id"
                                    autoComplete="netsuite-contact-id"
                                    label="NetSuite Contact ID"
                                    name="user.netSuiteContactId"
                                />

                                <Select
                                    name="user.title"
                                    label="Title"
                                    options={titles}
                                    placeholder="Select a title"
                                    dataTestId="title"
                                    dataTestIdItems="title__item"
                                    dataTestIdIndex={0}
                                />

                                <Input
                                    name="user.firstName"
                                    label="First name"
                                    autoComplete="first-name"
                                    dataTestId="firstname"
                                />

                                <Input
                                    name="user.lastName"
                                    label="Last name"
                                    autoComplete="last-name"
                                    dataTestId="lastname"
                                />

                                <Input
                                    name="user.email"
                                    label="Email"
                                    autoComplete="email"
                                    dataTestId="email"
                                />

                                <Input
                                    name="user.telephone"
                                    label="Telephone"
                                    dataTestId="telephone"
                                />

                                <Tags
                                    name="user.roles"
                                    label="Roles"
                                    options={roles}
                                    dataTestId="roles"
                                    dataTestIdItems="roles__item"
                                />

                                {customerGroups && customerGroups?.length > 0 && (
                                    <Field name="user.customerGroup">
                                        {({ field, form }) => (
                                            <div className="form-group">
                                                <label>Customer Group</label>
                                                <Select
                                                    name={field.name}
                                                    className="w-100"
                                                    isSearchable={true}
                                                    onChange={(option) => {
                                                        form.setFieldValue(field.name, option.value)
                                                    }}
                                                    options={customerGroups}
                                                    placeholder="Search for Customer Groups..."
                                                    dataTestId="customer-groups"
                                                    dataTestIdItems="customer-groups__item"
                                                    dataTestIdIndex={1}
                                                    allowClear={true}
                                                    required
                                                />
                                            </div>
                                        )}
                                    </Field>
                                )}

                                <Checkbox
                                    name="user.searchByPartNumber"
                                    label="Search by Part Number"
                                />
                            </>
                        )}
                    </Group>
                </Form>

                {mode === MODE_EDIT && (
                    <>
                        <AddressForm
                            values={values}
                            visibleFields={visibleFields}
                            setVisibleFields={setVisibleFields}
                            setFormData={setFormData}
                            titles={titles}
                            onSaveAddress={onSaveAddress}
                            {...props}
                        />

                        <Modal
                            isVisible={showUserDialog}
                            title="Delete User"
                            close={() => setShowUserDialog(false)}
                            buttons={[
                                {
                                    type: 'btn-outline-secondary',
                                    text: 'Close',
                                    action: () => setShowUserDialog(false),
                                },
                                {
                                    type: 'btn-danger',
                                    text: 'Delete User',
                                    action: () => onDelete(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="mb-4 d-block"
                            />
                            Are you sure you would like to permanently delete the account of{' '}
                            {values.user.email}? Deleted users cannot be recovered.
                        </Modal>

                        <Modal
                            isVisible={showPasswordReset}
                            title="Reset Password"
                            close={() => setShowPasswordReset(false)}
                            buttons={[
                                {
                                    type: 'btn-outline-secondary',
                                    text: 'Cancel',
                                    action: () => setShowPasswordReset(false),
                                },
                                {
                                    type: 'btn-success',
                                    text: sendingPasswordReset ? 'Sending Email...' : 'Send Email',
                                    disabled: sendingPasswordReset,
                                    action: () => onResetPassword(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="mb-4 d-block"
                            />
                            Are you sure you would like to generate a reset password email for{' '}
                            {values.user.email}?
                        </Modal>
                    </>
                )}
            </div>
        </>
    )
}

UserForm.displayName = 'UserForm'
UserForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setFormData: PropTypes.func,
    countries: PropTypes.array,
}

export default UserForm
