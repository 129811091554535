import React from 'react'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye'

import ConditionsListRow from './ConditionsListRow'

const ConditionsList = ({ name }) => {
    const defaultCondition = {
        attribute: {
            label: '',
            code: 'Please select...',
            options: [],
        },
        operator: 'and',
        values: [
            {
                operation: '=',
                value: '',
            },
        ],
    }

    return (
        <Field name={name}>
            {({ field, form }) => (
                <>
                    {form.values.type === 'dynamic' && (
                        <FieldArray name={field.name}>
                            {({ push, remove }) => {
                                return (
                                    <div className="form-group">
                                        <label>Conditions</label>

                                        {field.value &&
                                            field.value.length > 0 &&
                                            field.value.map((option, index) => (
                                                <div key={`condition_${index}`}>
                                                    <ConditionsListRow
                                                        {...{
                                                            index,
                                                            form,
                                                            field,
                                                            remove,
                                                        }}
                                                    />
                                                </div>
                                            ))}

                                        {!field.value && (
                                            <p className="py-3 my-3 text-center">
                                                No Conditions applied
                                            </p>
                                        )}

                                        <div className="d-flex align-items-center justify-content-end mt-4">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    push(defaultCondition)
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} /> Add new Condition
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-link"
                                                onClick={() => {}}
                                            >
                                                <FontAwesomeIcon icon={faEye} /> View Matching
                                                Products
                                            </button>
                                        </div>
                                    </div>
                                )
                            }}
                        </FieldArray>
                    )}
                </>
            )}
        </Field>
    )
}

export default ConditionsList
