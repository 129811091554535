import React, { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'

import { HydraTable, TextFilter, Modal } from '@peracto/peracto-ui'

import { GET_LIST, useClient } from '@peracto/client'

export const getProductName = (attributes) => {
    const nameAttr = attributes.find(({ attribute }) => attribute?.code === 'product_name')
    return nameAttr?.value || '-'
}

const ProductImage = ({ src, name }) => {
    let imgSrc = 'https://placehold.it/40/09f/fff'
    if (src?.media?.images) {
        imgSrc = src.media.images[0]
    }

    return <img src={imgSrc} title={name} alt={name} style={{ height: '40px', width: '40px' }} />
}

const ProductAssignmentList = ({ category, onDelete }) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(false)

    const { client } = useClient()

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    // const columns = useMemo(() => [ // TODO: check why useMemo was used without dependencies
    const columns = [
        {
            id: 'resources',
            Cell: (row) => <ProductImage src={row.cell.value} name={row.row.values.name} />,
            selectable: false,
        },
        {
            Header: 'Name',
            accessor: 'product.attributes',
            id: 'product_name',
            sortType: 'basic',
            Filter: (props) => <TextFilter label="Name" {...props} />,
            Cell: ({ cell: { value } }) => getProductName(value),
            selectable: false,
        },
        {
            Header: 'SKU',
            accessor: 'product.sku',
            sortType: 'basic',
            Filter: (props) => <TextFilter label="SKU" {...props} />,
            selectable: false,
        },

        {
            Header: 'Delete',
            id: 'delete',
            sortType: 'basic',
            Cell: (row) => (
                <button
                    className="btn btn-danger"
                    onClick={() => {
                        setItemToDelete(row.row.original)
                        setShowDeleteDialog(true)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            ),
            selectable: false,
        },
    ]

    const onFetchData = (options) => client(GET_LIST, 'category-product-links', options)

    return (
        <div className="row">
            <div className="col">
                <HydraTable
                    columns={columns}
                    onFetchData={onFetchData}
                    defaultFilters={{
                        product_name: '',
                        'product.sku': '',
                    }}
                    alwaysFilter={{ category: category.id }}
                    showFilterToggle={false}
                />

                <Modal
                    isVisible={showDeleteDialog}
                    title="Delete Product Link"
                    close={() => setShowDeleteDialog(false)}
                    buttons={[
                        {
                            type: 'btn-outline-secondary',
                            text: 'Close',
                            action: () => {
                                setShowDeleteDialog(false)
                            },
                        },
                        {
                            type: 'btn-danger',
                            text: 'Delete',
                            action: () => {
                                setShowDeleteDialog(false)
                                onDelete(itemToDelete.id)
                            },
                        },
                    ]}
                >
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        size="4x"
                        className="d-block mb-4"
                    />
                    Are you sure you would like to remove{' '}
                    {itemToDelete?.product?.attributes
                        ? getProductName(itemToDelete.product.attributes)
                        : 'this product'}{' '}
                    from this category?
                </Modal>
            </div>
        </div>
    )
}

export default ProductAssignmentList
