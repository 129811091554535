import React from 'react'
import { Field } from 'formik'

import * as S from './styled'

const SearchEnginePreview = () => {
    return (
        <S.SearchEnginePreview>
            <S.SearchEngineUrl>
                <Field name="identifier">
                    {({ field }) => (
                        <>{`${window.location.origin}/c/${field.value || 'category-url'}`}</>
                    )}
                </Field>
            </S.SearchEngineUrl>
            <Field name="identifier">
                {({ field }) => (
                    <S.SearchEngineTitle href={`${field.value}`} target="_blank">
                        <Field name="metaTitle">
                            {({ field, form }) => {
                                if (field?.value?.length) {
                                    return field.value
                                } else {
                                    return form?.values?.name ? form.values.name : 'Category Name'
                                }
                            }}
                        </Field>
                    </S.SearchEngineTitle>
                )}
            </Field>

            <S.SearchEngineDescription>
                <Field name="metaDescription">
                    {({ field }) => {
                        if (field?.value?.length) {
                            return field.value
                        } else {
                            return (
                                <em>
                                    You have no description set. Descriptions are important and can
                                    influence click-through rates. The optimal length is between
                                    50–160 characters.
                                </em>
                            )
                        }
                    }}
                </Field>
            </S.SearchEngineDescription>
        </S.SearchEnginePreview>
    )
}

export default SearchEnginePreview
