import React from 'react'
import Sortable from 'react-sortablejs'

import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo'
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'

import { Textarea, File, SortableRow } from '@peracto/peracto-ui'

const ResourceIcon = ({ type, src = '', dataTestId }) => {
    switch (type) {
        case 'main_image':
        case 'secondary_image':
            return (
                <img
                    alt=""
                    src={src}
                    style={{ width: '100%' }}
                    title={src}
                    data-testid={dataTestId}
                />
            )
        case 'video':
            return <FontAwesomeIcon icon={faVideo} title={src} />
        default:
            return <FontAwesomeIcon icon={faFile} title={src} />
    }
}

const Resources = ({ groupLabel = '', group }) => {
    return (
        <Field name={`categoryResources.${group}`}>
            {({ field, form }) => (
                <FieldArray name={field.name}>
                    {({ push, remove }) => (
                        <>
                            <div className="form-group">
                                <label className="mb-1">{groupLabel}s</label>
                                {field?.value?.length > 0 && (
                                    <table className="table table-sm mb-1">
                                        <colgroup>
                                            <col width="5%" />
                                            <col width="10%" />
                                            <col width="30%" />
                                            <col width="40%" />
                                            <col width="15%" />
                                        </colgroup>

                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th className="text-right">Remove</th>
                                            </tr>
                                        </thead>

                                        <Sortable
                                            tag="tbody"
                                            onChange={(order, sortable, evt) => {
                                                const values = [...field.value]
                                                const movedItem = values[evt.oldIndex]
                                                const to = evt.newIndex
                                                values.splice(evt.oldIndex, 1)
                                                values.splice(to, 0, movedItem)
                                                form.setFieldValue(field.name, values)
                                            }}
                                            options={{
                                                animation: 100,
                                                handle: '.handle',
                                            }}
                                        >
                                            {field.value.map((item, index) => (
                                                <SortableRow
                                                    key={index}
                                                    data-id={index}
                                                    data-testid={`${group}s__item`}
                                                >
                                                    <td>
                                                        <span
                                                            className="handle"
                                                            data-testid={`${group}s__item__slider-handle`}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={['far', 'grip-lines']}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td className="text-center">
                                                        <ResourceIcon
                                                            type={group}
                                                            src={item.path}
                                                            dataTestId={`${group}s__item__image`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Textarea
                                                            name={`categoryResources.${group}.${index}.title`}
                                                            label=""
                                                            placeholder="Title"
                                                            dataTestId={`${group}s__item__title`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Textarea
                                                            name={`categoryResources.${group}.${index}.description`}
                                                            label=""
                                                            placeholder="Description"
                                                            dataTestId={`${group}s__item__description`}
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-danger"
                                                            onClick={() => {
                                                                remove(index, 1)
                                                            }}
                                                            data-testid={`${group}s__item__remove`}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </td>
                                                </SortableRow>
                                            ))}
                                        </Sortable>
                                    </table>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <File
                                            dataTestId={`${group}s__input`}
                                            name={`resources_${groupLabel}`}
                                            placeholder={`Add a new ${groupLabel.toLowerCase()} to the product`}
                                            onSelectCallback={(value) => {
                                                if (value.length > 0) {
                                                    push({
                                                        path: value,
                                                        title: value,
                                                        description: '',
                                                    })
                                                }
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault()

                                                    if (e.target.value.length > 0) {
                                                        push({
                                                            path: e.target.value,
                                                            title: e.target.value,
                                                            description: '',
                                                        })

                                                        e.target.value = ''
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </FieldArray>
            )}
        </Field>
    )
}

export default Resources
