import {
    Container,
    Text,
    Image,
    Video,
    YouTube,
    Embed,
    Map,
    Form,
} from "@peracto/peracto-blocks";

export default {
    plugins: {
        content: [Text, Image, Video, YouTube, Embed, Map, Form],
        layout: [Container],
    },
};
