import React, { useState } from 'react'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'

import { toast } from 'react-toastify'

import { Form, Group, Input, Select, Tags, Modal } from '@peracto/peracto-ui'
import { DELETE, useClient } from '@peracto/client'
import * as S from './styled'

const AddressForm = ({
    values,
    visibleFields,
    setVisibleFields,
    countries,
    titles,
    onSaveAddress,
    setFormData,
    ...props
}) => {
    const [showAddressDialog, setShowAddressDialog] = useState(false)
    const [addressDialogContent, setAddressDialogContent] = useState({})
    const { client } = useClient()

    const addressRow = {
        title: '',
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        city: '',
        county: '',
        postcode: '',
        country: '',
        type: ['delivery'],
    }

    const onDeleteAddress = async (addressId) => {
        try {
            await client(DELETE, 'addresses', {
                id: addressId,
            })

            toast.success('Address deleted successfully!')

            setFormData({
                ...values,
                addresses: values.addresses.filter((address) => address['@id'] !== addressId),
            })

            setVisibleFields({})
            setShowAddressDialog(false)
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const getCountry = (value) => {
        const country = countries.find((country) => value === country.iso3)
        return country ? country.label : value
    }

    const getCountryOptions = (type) => {
        return countries
            .filter((country) => {
                if (
                    (!country.validForBilling && type === 'delivery') ||
                    (!country.validForDelivery && type === 'billing')
                ) {
                    return false
                }

                return true
            })
            .map((country) => ({ label: country.label, value: country.iso3 }))
    }

    return (
        <>
            <Form autoComplete="off" values={values} showActionPanel={false} {...props}>
                <Group key="addresses" id="addresses" name="Addresses">
                    <Field name="addresses">
                        {({ form, field }) => (
                            <FieldArray name={field.name}>
                                {({ push }) => (
                                    <div>
                                        {field.value.length > 0 ? (
                                            field.value.map((address, idx) => (
                                                <div
                                                    className="d-flex justify-content-between align-items-start mb-3"
                                                    key={`address-${address.originId}`}
                                                >
                                                    {!visibleFields[`address-${idx}`] && (
                                                        <S.Address>
                                                            <p className="address-name">
                                                                {address.title} {address.firstName}{' '}
                                                                {address.lastName}
                                                            </p>
                                                            {address.addressLine1 && (
                                                                <p>{address.addressLine1}</p>
                                                            )}
                                                            {address.addressLine2 && (
                                                                <p>{address.addressLine2}</p>
                                                            )}
                                                            {address.addressLine3 && (
                                                                <p>{address.addressLine3}</p>
                                                            )}
                                                            {address.addressLine4 && (
                                                                <p>{address.addressLine4}</p>
                                                            )}
                                                            {address.city && <p>{address.city}</p>}
                                                            {address.county && (
                                                                <p>{address.county}</p>
                                                            )}
                                                            {address.postcode && (
                                                                <p>{address.postcode}</p>
                                                            )}
                                                            {address.country && (
                                                                <p>{getCountry(address.country)}</p>
                                                            )}
                                                        </S.Address>
                                                    )}

                                                    {visibleFields[`address-${idx}`] && (
                                                        <div className="w-100">
                                                            <Select
                                                                name={`addresses.${idx}.title`}
                                                                label="Title"
                                                                options={titles}
                                                                placeholder="Select a title"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.firstName`}
                                                                label="First name"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.lastName`}
                                                                label="Last name"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.companyName`}
                                                                label="Company Name"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine1`}
                                                                label="Address Line 1"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine2`}
                                                                label="Address Line 2"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine3`}
                                                                label="Address Line 3"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine4`}
                                                                label="Address Line 4"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.city`}
                                                                label="City"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.county`}
                                                                label="County"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.postcode`}
                                                                label="Postcode"
                                                            />
                                                            <Select
                                                                name={`addresses.${idx}.country`}
                                                                label="Country"
                                                                options={getCountryOptions(
                                                                    field.value[idx].type,
                                                                )}
                                                                placeholder="Select a country"
                                                            />

                                                            <div className="d-flex">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success d-flex align-items-center"
                                                                    onClick={() => {
                                                                        onSaveAddress(address)
                                                                        setVisibleFields({
                                                                            ...visibleFields,
                                                                            [`address-${idx}`]: false,
                                                                        })
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faSave}
                                                                        className="mr-2"
                                                                    />{' '}
                                                                    Save Address
                                                                </button>

                                                                {field.value[idx].id && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-danger d-flex align-items-center ml-2"
                                                                        onClick={() => {
                                                                            setAddressDialogContent(
                                                                                address,
                                                                            )
                                                                            setShowAddressDialog(
                                                                                true,
                                                                            )
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className="mr-2"
                                                                        />{' '}
                                                                        Delete Address
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {!address.type.some(
                                                        (type) => type === 'billing',
                                                    ) && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-link p-0 d-flex align-items-center"
                                                            onClick={() =>
                                                                setVisibleFields({
                                                                    ...visibleFields,
                                                                    [`address-${idx}`]: !visibleFields[
                                                                        `address-${idx}`
                                                                    ],
                                                                })
                                                            }
                                                        >
                                                            {visibleFields[`address-${idx}`] ? (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={faTimes}
                                                                        className="mr-2"
                                                                    />
                                                                    Close
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="mr-2"
                                                                    />
                                                                    Edit
                                                                </>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-center mt-3 mb-0">
                                                No addresses are assigned to this user.
                                            </p>
                                        )}

                                        <div className="d-flex justify-content-end mb-2">
                                            <button
                                                className="btn btn-sm btn-outline-primary my-2"
                                                type="button"
                                                onClick={() => {
                                                    push(addressRow)
                                                    setVisibleFields({
                                                        ...visibleFields,
                                                        [`address-${field.value.length}`]: true,
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['far', 'plus']}
                                                    className="mr-2"
                                                />{' '}
                                                Add New Address
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                        )}
                    </Field>
                </Group>
            </Form>

            <Modal
                isVisible={showAddressDialog}
                title="Delete User"
                close={() => setShowAddressDialog(false)}
                buttons={[
                    {
                        type: 'btn-outline-secondary',
                        text: 'Close',
                        action: () => setShowAddressDialog(false),
                    },
                    {
                        type: 'btn-danger',
                        text: 'Delete Address',
                        action: () => onDeleteAddress(addressDialogContent['@id']),
                    },
                ]}
            >
                <FontAwesomeIcon
                    icon={['far', 'exclamation-triangle']}
                    size="4x"
                    className="d-block mb-4"
                />
                Are you sure you would like to permanently delete the address for{' '}
                {addressDialogContent.title} {addressDialogContent.firstName}{' '}
                {addressDialogContent.lastName} ?
            </Modal>
        </>
    )
}

export default AddressForm
