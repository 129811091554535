import React from 'react'
import { GET_LIST, useClient } from '@peracto/client'
import { Select, Input } from '@peracto/peracto-ui'
import AsyncSelect from 'react-select/async'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

import debounce from 'debounce-promise'

export const operatorValues = [
    { label: 'Equals', value: '=' },
    { label: 'Greater than', value: '>' },
    { label: 'Less than', value: '<' },
    { label: 'Greater than or equal', value: '>=' },
    { label: 'Less than or equal', value: '<=' },
    { label: 'Not equal to', value: '!=' },
]

const rowOperatorValues = [
    { label: 'And', value: 'and' },
    { label: 'Or', value: 'or' },
]

const stringOperatorValues = [{ label: 'Equals', value: '=' }]

const STRING_OPERATOR_TYPES = ['text', 'string']

const ConditionsListForm = ({ index, form, field, remove, toggleRow }) => {
    const { client } = useClient()

    const fetchAttributes = async (inputValue = '') => {
        const { data } = await client(GET_LIST, 'attributes', {
            id: 'attributes',
            label: inputValue,
        })

        const values = data.map((attr) => ({
            label: `${attr.label} (${attr.code})`,
            value: {
                label: attr.label,
                code: attr.code,
                dataType: attr.dataType,
                options: attr.options,
            },
        }))

        return values
    }

    const debouncedFetchAttributes = debounce(fetchAttributes, 200)

    return (
        <div key={`condition_${index}`} className="border-bottom py-3">
            <div className="row mb-1">
                <div className="col-12">
                    <div className="form-row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end align-items-center">
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-link text-danger px-0 mr-3"
                                        onClick={() => remove(index)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} /> Remove Condition
                                    </button>

                                    <button
                                        className="btn btn-link px-0"
                                        onClick={() => toggleRow()}
                                    >
                                        <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                        Close Condition
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 col-md-4">
                            <Field name={`${field.name}.${index}.attribute`}>
                                {({ form, field }) => (
                                    <>
                                        <label className="mb-1">Attribute</label>
                                        <AsyncSelect
                                            className="w-100"
                                            loadOptions={(input) => debouncedFetchAttributes(input)}
                                            isSearchable={true}
                                            onChange={(option) => {
                                                form.setFieldValue(field.name, option.value)
                                            }}
                                            value={{
                                                label: `${field.value.label} (${field.value.code})`,
                                                value: field.value,
                                            }}
                                            placeholder="Search for Attributes to add..."
                                            noOptionsMessage={({ inputValue }) => {
                                                if (inputValue.length > 0) {
                                                    return `No results found for '${inputValue}'.`
                                                } else {
                                                    return 'Enter text to begin searching.'
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </Field>
                        </div>
                        <div className="col-12 col-md-2">
                            <Select
                                name={`${field.name}.${index}.operator`}
                                placeholder="Select..."
                                options={rowOperatorValues}
                                className="form-control form-control-sm"
                                label="Operator"
                                disabled={
                                    form.values?.options?.attributes[index]?.values?.length < 2
                                }
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="mb-1">Values</label>
                            <Field name={`${field.name}.${index}.values`}>
                                {({ form, field }) => (
                                    <FieldArray name={field.name}>
                                        {({ push, remove }) => (
                                            <>
                                                {field.value &&
                                                    field.value.length > 0 &&
                                                    field.value.map((option, valIdx) => (
                                                        <div
                                                            className="form-row"
                                                            key={`${field.name}.${valIdx}`}
                                                        >
                                                            <div className="col-12 col-md-6">
                                                                <Select
                                                                    name={`${field.name}.${valIdx}.operation`}
                                                                    placeholder="Select..."
                                                                    options={
                                                                        STRING_OPERATOR_TYPES.includes(
                                                                            form.values.options
                                                                                .attributes[index]
                                                                                .attribute.dataType,
                                                                        )
                                                                            ? stringOperatorValues
                                                                            : operatorValues
                                                                    }
                                                                    className="form-control form-control-sm"
                                                                />
                                                            </div>
                                                            <div className="col-12 col-md-5">
                                                                {form.values.options.attributes[
                                                                    index
                                                                ].attribute.options.length > 0 ? (
                                                                    <Select
                                                                        name={`${field.name}.${valIdx}.value`}
                                                                        placeholder="Select..."
                                                                        options={
                                                                            form.values.options
                                                                                .attributes[index]
                                                                                .attribute.options
                                                                        }
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                ) : (
                                                                    <Input
                                                                        name={`${field.name}.${valIdx}.value`}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="col-12 col-md-1">
                                                                {valIdx > 0 && (
                                                                    <div className="d-flex justify-content-end">
                                                                        <button
                                                                            className="btn btn-link px-0"
                                                                            onClick={(e) => {
                                                                                e.preventDefault()
                                                                                remove(valIdx)
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faTimesCircle}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}

                                                {!field.value ||
                                                    (field.value.length === 0 && (
                                                        <p className="py-3 my-3 text-center">
                                                            No values applied
                                                        </p>
                                                    ))}

                                                <div className="d-flex justify-content-end align-items-end">
                                                    <button
                                                        className="btn btn-link px-0"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            push({
                                                                operation: '=',
                                                                value: '',
                                                            })
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPlusCircle}
                                                            className="mr-2"
                                                        />
                                                        Add Value
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </FieldArray>
                                )}
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConditionsListForm
